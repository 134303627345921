@use 'sass:math';

@font-face {
  font-family: 'VAGRundschriftD';
  src: url(assets/VAGRundschriftD.woff) format('woff'), 
      url(assets/VAGRundschriftD.ttf) format('truetype');
}

@font-face {
  font-family: 'FranklinGothicURW-Boo';
  src: url(assets/VAGRundschriftD.woff) format('woff'), 
      url(assets/VAGRundschriftD.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSansRegular';
  src: url(assets/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSansSemiBold';
  src: url(assets/OpenSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSansBold';
  src: url(assets/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSansLight';
  src: url(assets/OpenSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'BwSeidoRaw';
  src: url(assets/BwSeidoRawBold/font.woff2) format('woff2');
  font-weight: bold;
}
@font-face {
  font-family: 'BwSeidoRaw';
  src: url(assets/BwSeidoRawRegular/font.woff2) format('woff2');
  font-weight: normal;
}
@font-face {
  font-family: 'BwSeidoRound';
  src: url(assets/BwSeidoRoundBlack/font.woff2) format('woff2');
  font-weight: bolder;
}
@font-face {
  font-family: 'BwSeidoRound';
  src: url(assets/BwSeidoRoundBold/font.woff2) format('woff2');
  font-weight: bold;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'BwSeidoRaw';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-stretch: normal;
  font-feature-settings: "ss01";
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.hide-focus button:focus,
.hide-focus input:focus,
.hide-focus textarea:focus,
.hide-focus a:focus {
  outline: none;
}



