@use 'sass:math';

@import "~@wordpress/base-styles/colors";
@import "~@wordpress/base-styles/variables";
@import "~@wordpress/base-styles/mixins";
@import "~@wordpress/base-styles/breakpoints";
@import "~@wordpress/base-styles/animations";
@import "~@wordpress/base-styles/z-index";

@import '~@wordpress/block-library/src/style.scss';
@import '~@wordpress/block-library/src/theme.scss';

$lemon: #ffe64b;
$apila: #8cd280; 
$black: #05262c;

.wp_content {

  p, a, span {
    font-family: 'BwSeidoRaw';
    font-feature-settings: "ss01";
  }
  h1, h2, h3 {
    font-family: 'BwSeidoRound';
    font-feature-settings: "ss01";
  }
  h1 {
    font-size: 42px;
    
    @media (max-width: 850px)  {
      font-size: 36px;
    }
    @media (max-width: 670px) {
      font-size: 24px;
    }
  }
  h2 {
    font-size: 32px;

    @media (max-width: 850px)  {
      font-size: 24px;
    } 
    @media (max-width: 670px) {
      font-size: 20px;
      font-weight: 700;
    }
  }
  .wp-block-group {
    border-radius: 24px;
    padding: 12px 24px !important;
    margin-top: 55px;
    .wp-block-group__inner-container {
      h2:first-of-type, h3:first-of-type {
        margin-top: 0;
      }
    }
    &.box-lemon {
      background-color: $lemon;
    }
  }
  .wp-block-rss__item {
      display: flex;
      margin-bottom: 24px;
      align-items: center;
      time {
          order: 0;
          background-color: $black;
          color: white;
          padding: 6px 20px;
          border-radius: 16px;
          font-size: 16px;
          margin-right: 24px;
      }
      div {
          order: 1;
          a {
              color: $black;
              &:hover {
                text-decoration: underline;
              }
          }
      }
  }
  .wp-block-image {
    img { 
      height: 100%;
    }
  }
  
  // Alignment for icons and mobile screens
  .helper-icon {
    .wp-block-image {
      margin: 0;
      img {
        vertical-align: middle;
      }
    }
    // Width taken from DeviceSize.tsx, corresponds to mobileL
    @media screen and (max-width: 670px) {
      flex-basis: 15% !important;
      margin-right: 5px;
    }
  }
  .wp-block-columns .icon-paragraph {
    margin-left: 0 !important;
    min-height: 50px;
    @media screen and (max-width: 670px) {
      flex-basis: 85% !important;
    }
  }
  
  .wp-block-columns {
    flex-wrap: nowrap;
  }
}
